class Elementor_Tabs {
    static instance;

    static getInstance() {
        if (!Elementor_Tabs.instance) {
            Elementor_Tabs.instance = new Elementor_Tabs();
        }
        return Elementor_Tabs.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-tabs.default', ($scope) => {
            $scope.addClass('elementor-widget-tabs');
            let $tabs = $scope.find('.elementor-tabs-wrapper');
            let $contents = $scope.find('.elementor-tabs-content-wrapper');

            // Active tab
            $contents.find('.elementor-active').show();

            $tabs.find('.elementor-tab-title').on('click', function () {
                $tabs.find('.elementor-tab-title').removeClass('elementor-active');
                $contents.find('.elementor-tab-content').removeClass('elementor-active').hide();
                $(this).addClass('elementor-active');
                let id = $(this).attr('aria-controls');
                $contents.find('#' + id).addClass('elementor-active').show();

            })

        });
    }
}

Elementor_Tabs.getInstance();