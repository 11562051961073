class Elementor_Testimonial {
    static instance;

    static getInstance() {
        if (!Elementor_Testimonial.instance) {
            Elementor_Testimonial.instance = new Elementor_Testimonial();
        }
        return Elementor_Testimonial.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-testimonials.default', ($scope) => {
            let $carousel = $scope.find('.owl-carousel');
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }
            let $wrapper = $scope.find('.elementor-testimonial-wrapper');
            if ($wrapper.hasClass('layout_2')) {
                let $imagewraper = $scope.find('.elementor-testimonial-image');
                let $owlnav = $scope.find('.owl-nav');
                let $width = $imagewraper.outerWidth(true) + 7;
                $owlnav.css('margin-left', $width);
            }
            let $wrapper_carousel = $scope.find('.owl-stage-outer');
            let $item_carousel = $scope.find('.owl-item');
            $item_carousel.height($wrapper_carousel.height());

        });
    }
}


Elementor_Testimonial.getInstance();