class Elementor_Image_Gallery {
    static instance;

    static getInstance() {
        if (!Elementor_Image_Gallery.instance) {
            Elementor_Image_Gallery.instance = new Elementor_Image_Gallery();
        }
        return Elementor_Image_Gallery.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-image-gallery.default', ($scope) => {
            let $list = $scope.find('.column-item');

            $list.each((index, element) => {
                $(element).hoverdir({
                    speed: 1,
                });
            });
            let $iso = $scope.find('.isotope-grid');

            $iso.imagesLoaded(function () {
                $iso.masonry({
                    itemSelector: '.column-item',
                    columnWidth: '.column-item:nth-child(2)',
                    percentPosition: true,
                });
            });

            if ($scope.find('.elementor-galerry__filters').length !== 0) {
                let currentIsotope = $iso.isotope({filter: '.gallery_group_0'});
                $scope.find('.elementor-galerry__filters li').on('click', function () {
                    $(this).parents('ul.elementor-galerry__filters').find('li.elementor-galerry__filter').removeClass('elementor-active');
                    $(this).addClass('elementor-active');
                    let selector = $(this).attr('data-filter');
                    currentIsotope.isotope({filter: selector});
                });

                currentIsotope.imagesLoaded(function () {
                    $iso.isotope({filter: '.gallery_group_0'});
                });
            }

            $scope.find('.isotope-grid .grid__item').magnificPopup({
                delegate: 'a',
                type: 'image',
                tLoading: 'Loading image #%curr%...',
                mainClass: 'mfp-img-mobile',
                gallery: {
                    enabled: true,
                    navigateByImgClick: true,
                    preload: [0, 1]
                },
                image: {
                    tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
                    titleSrc: function (item) {
                        return item.el.attr('title');
                    }
                }
            });
        });
    }

    renderItem(images) {
        return `<div class="column-item grid__item masonry-item __all gallery_group_${images['group']}">
                    <a data-elementor-open-lightbox="no" href="${images['url']}">
                                <img src="${images['thumbnail_url']}" alt=""/>
                                <div class="gallery-item-overlay">
                                    <i class="opal-icon-zoom"></i>
                                </div>
                            </a>
                 </div>`;
    }
}

Elementor_Image_Gallery.getInstance();

