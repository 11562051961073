class Elementor_Featured_Box {
    static instance;

    static getInstance() {
        if (!Elementor_Featured_Box.instance) {
            Elementor_Featured_Box.instance = new Elementor_Featured_Box();
        }
        return Elementor_Featured_Box.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/featured-box.default', ($scope) => {

            setTimeout(() => {
                let $object = $scope.find('object');
                if ($object.length > 0) {
                    let a = $object.get(0);
                    let svgDoc = a.contentDocument;
                    $object.after($(svgDoc).find('svg'));
                    $object.remove();
                }
            }, 200);
        });

    }
}


Elementor_Featured_Box.getInstance();