class Elementor_Image_Layers {
    static instance;

    static getInstance() {
        if (!Elementor_Image_Layers.instance) {
            Elementor_Image_Layers.instance = new Elementor_Image_Layers();
        }
        return Elementor_Image_Layers.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-images-layers.default', ($scope, $) => {
            var premiumImgLaysElem = $scope.find('.opal-img-layers-wrapper');
            $(premiumImgLaysElem).find('.opal-img-layers-list-item').each(function () {
                var _this = $(this);
                if (_this.data('layer-animation') && ' ' != _this.data('layer-animation')) {
                    let timeout = _this.data('layer-animation-delay') * 1000 || 0;
                    _this.css("opacity", "0");
                    var waypoint = new Waypoint({
                        element: $(premiumImgLaysElem),
                        offset: $.waypoints('viewportHeight') - 150,
                        handler: function () {
                            setTimeout(() => {
                                _this.css("opacity", "1");
                            }, timeout)
                            _this.addClass("animated " + _this.data('layer-animation'));
                        }
                    });
                }
            });
            premiumImgLaysElem.mousemove(function (e) {
                premiumImgLaysElem.find('.opal-img-layers-list-item[data-parallax="true"]').each(function (index, element) {
                    $(this).jsparallax($(this).data('rate'), e);
                });
            });
            const tilts = premiumImgLaysElem.find('.opal-img-layers-list-item[data-tilt="true"]');
            const liveTilt = new UniversalTilt(tilts, {
                onMouseLeave: el => {
                    el.style.boxShadow = '0 45px 100px rgba(255, 255, 255, 0)';
                },
                onDeviceMove: el => {
                    el.style.boxShadow = '0 45px 100px rgba(255, 255, 255, 0.3)';
                },
                mobile: false
            });
        });
    }
}

Elementor_Image_Layers.getInstance();