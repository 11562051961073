class Elementor_Google_Map {
    static instance;

    static getInstance() {
        if (!Elementor_Google_Map.instance) {
            Elementor_Google_Map.instance = new Elementor_Google_Map();
        }
        return Elementor_Google_Map.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-google_map.default', ($scope) => {
            let opal_map = $scope.find('.opal-google-maps');
            if (opal_map.length > 0) {
                for (let i = 0; i < opal_map.length; i++) {
                    let mapElement = $(opal_map[i]);
                    this.google_map_render(opal_map[i], mapElement.data());
                }
            }

        });
    }

    google_map_render(ele, options) {
        var defaults = {
            // How zoomed in you want the map to start at (always required)
            zoom: 11,
            scrollwheel: false,
        };
        options = jQuery.extend({}, defaults, options);

        options.center = new google.maps.LatLng(parseFloat(options.lat), parseFloat(options.lng));
        // Create the Google Map using our element and options defined above
        var map = new google.maps.Map(ele, options);

        // Let's also add a marker while we're at it
        var marker = new google.maps.Marker({
            position: options.center,
            map: map,
            zoom: options.zoom,
            styles: options.styles
        });
    }
}


Elementor_Google_Map.getInstance();