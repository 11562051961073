class Elementor_Classes {
    static instance;

    static getInstance() {
        if (!Elementor_Classes.instance) {
            Elementor_Classes.instance = new Elementor_Classes();
        }
        return Elementor_Classes.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-classes.default', ($scope) => {

            let currentIsotope = $scope.find('.isotope-grid').isotope({filter: '*'});
            $scope.find('.elementor-classes__filters li').on('click', function () {
                $(this).parents('ul.elementor-classes__filters').find('li.elementor-classes__filter').removeClass('elementor-active');
                $(this).addClass('elementor-active');
                let selector = $(this).attr('data-filter');
                currentIsotope.isotope({filter: selector});
            });

            currentIsotope.imagesLoaded(function () {
                currentIsotope.isotope('layout');
            });

            let $carousel = $scope.find('.owl-carousel');
            if($carousel.length > 0){
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }
            // let $wrapper_carousel = $scope.find('.owl-stage-outer');
            // let $item_carousel = $scope.find('.owl-item');
            // $item_carousel.height($wrapper_carousel.height());
        });
    }
}

Elementor_Classes.getInstance();