class Elementor_Timeline {
    static instance;

    static getInstance() {
        if (!Elementor_Timeline.instance) {
            Elementor_Timeline.instance = new Elementor_Timeline();
        }
        return Elementor_Timeline.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-timeline.default', ($scope, $) => {

            let $timeline = $scope.find('.opal-timeline-wrapper');
            $timeline.append('<div class="opal-timeline-line"></div>');

            let $timeline1 = $scope.find('.opal-timeline > div:last-child').height();
            let $timeline2 = $scope.find('.opal-timeline > div:first-child').height();
            let nummerTimeline = $timeline.data('timeline-count');
            // $(".opal-timeline-line").css({"top": $timeline1 / 2, "bottom": $timeline2 / 2});
            for (var i = 0; i < nummerTimeline; i++) {
                var premiumImgLaysElem = $scope.find('.timeline-item-' + i);
                $(premiumImgLaysElem).find('.timeline-animation').each(function () {
                    var _this = $(this);
                    if (_this.data('timeline-animation') && ' ' != _this.data('timeline-animation')) {
                        let timeout = _this.data('timeline-animation-delay') * 1000 || 0;
                        _this.css("opacity", "0");

                        var timeline_waypoint = new Waypoint({
                            element: $(premiumImgLaysElem),
                            offset: $.waypoints('viewportHeight') - 150,
                            handler: function () {
                                setTimeout(() => {
                                    _this.css("opacity", "1");
                                }, timeout)
                                _this.addClass("animated " + _this.data('timeline-animation'));
                            }
                        });
                    }
                });
            }

        });
    }
}

Elementor_Timeline.getInstance();