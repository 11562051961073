class Elementor_Toggle {

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/toggle.default', ($scope) => {
            if ($scope.hasClass('elementor-event-type-accordion')) {
                $scope.find('.elementor-tab-title').on('click', function () {
                    let tabid = $(this).data('tab');
                    $scope.find('.elementor-toggle-item').each(function (index, element) {
                        if (tabid !== index + 1) {
                            let $container = $(element);
                            $container.find('.elementor-tab-title').removeClass('elementor-active');
                            $container.find('.elementor-tab-content').slideUp();
                        }
                    });
                })
            }
        });
    }


}

new Elementor_Toggle();